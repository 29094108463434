import { IonCol, IonGrid, IonInput, IonRange, IonRow } from '@ionic/react';
import { ReactElement, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from '@components/inputs/Controls.module.css';
import NumberType from '@typing/NumberType';
import { updateValueAsNumberFromInput } from '@utils/formUtils';

type Props = {
  explanation: ReactElement | string;
  labelKey: string;
  maxValue: number;
  minValue: number;
  numberType: NumberType;
  setValue: (value: number | undefined) => void;
  step: number;
  value: number | undefined;
};

const NumberRangeInput = ({ explanation, labelKey, maxValue, minValue, numberType, setValue, step, value }: Props) => {
  const inputRef = useRef<HTMLIonInputElement>(null);

  const inputClass = useMemo(() => {
    if (numberType === NumberType.CURRENCY) {
      return styles.currencyInput;
    } else if (numberType === NumberType.PERCENTAGE) {
      return styles.percentInput;
    }
    return '';
  }, [numberType]);

  useEffect(() => {
    const current = inputRef.current;

    current?.addEventListener('wheel', e => e.preventDefault());
    return () => {
      current?.removeEventListener('wheel', e => e.preventDefault());
    };
  }, [inputRef]);

  return (
    <div className={styles.controlSection}>
      <div className={styles.controlTitle}>
        <FormattedMessage id={labelKey} />
      </div>
      <IonGrid className="ion-no-padding ion-no-margin">
        <IonRow className="ion-no-padding ion-no-margin ion-align-items-center">
          <IonCol>
            <IonRange
              className={styles.range}
              max={maxValue}
              min={minValue}
              onIonChange={({ detail }) => updateValueAsNumberFromInput(detail.value as number, setValue, false)}
              step={step}
              value={value}
            ></IonRange>
          </IonCol>
          <IonCol className={styles.inputColumn}>
            <IonInput
              className={`${styles.input} ${inputClass}`}
              onIonChange={({ detail }) => updateValueAsNumberFromInput(detail.value, setValue, false)}
              ref={inputRef}
              type="number"
              value={value}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className="ion-no-padding ion-no-margin">
          <IonCol className={styles.controlExplanation}>{explanation}</IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default NumberRangeInput;
