/* eslint-disable @typescript-eslint/naming-convention */
const Constants = {
  CAREGIVING_RATE: 0.73,
  CONTRACT_YEARS: 3.0,
  DEFAULT_AVERAGE_COMP: 75000,
  DEFAULT_COST_OF_GRAYCE: undefined,
  DEFAULT_HEADCOUNT: 5000,
  DEFAULT_TURNOVER_COST: 9,
  DEFAULT_TURNOVER_RATE: 20,
  FOUR_WEEKS_SAVED_RATE: 0.07,
  GRAYCE_FULL_UTILIZATION_ESTIMATE: 0.4,
  GRAYCE_TURNOVER_REDUCTION_RATE: 0.3841,
  ONE_DAY_SAVED_RATE: 0.21,
  ONE_WEEK_SAVED_RATE: 0.34,
  SEGMENTATION_CHILD_RATE: 0.13,
  SEGMENTATION_DOMESTIC_RATE: 0.69,
  SEGMENTATION_INTERNATIONAL_RATE: 0.31,
  SEGMENTATION_MID_LIFE_RATE: 0.28,
  SEGMENTATION_SENIOR_RATE: 0.58,
  STRUGGLING_RATE: 0.15,
  UTILIZATION_RATE_HIGH: 100.0,
  UTILIZATION_RATE_LOW: 60.0
};

export default Constants;
