import { IonCol, IonGrid, IonRow, IonToggle } from '@ionic/react';
import { ReactElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from '@components/inputs/Controls.module.css';

type Props = {
  explanation: ReactElement | string;
  labelKey: string;
  offValue: number;
  onValue: number;
  setValue: (value: number) => void;
  value: number;
};

const ToggleInput = ({ explanation, labelKey, offValue, onValue, setValue, value }: Props) => {
  const handleToggled = useCallback(
    (checked: boolean) => {
      setValue(checked ? onValue : offValue);
    },
    [offValue, onValue, setValue]
  );

  return (
    <div className={styles.controlSection}>
      <div className={styles.controlTitle}>
        <FormattedMessage id={labelKey} />
      </div>
      <IonGrid className="ion-no-padding ion-no-margin">
        <IonRow className="ion-no-padding ion-no-margin ion-align-items-center">
          <IonCol>
            <IonToggle
              checked={value === onValue}
              className={styles.toggle}
              onIonChange={({ detail }) => handleToggled(detail.checked)}
            ></IonToggle>
          </IonCol>
        </IonRow>
        <IonRow className="ion-no-padding ion-no-margin">
          <IonCol className={styles.toggleControlExplanation}>{explanation}</IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ToggleInput;
