import styles from '@components/roiPageSections/Resources.module.css';

const Resources = () => (
  <ul className={styles.linkList}>
    <li>
      <a
        href="https://www.hbs.edu/managing-the-future-of-work/Documents/The_Caring_Company.pdf"
        rel="noreferrer"
        target="_blank"
      >
        Fuller, J. And M. Raman, (2019). “The Caring Company.” <em>Harvard Business School</em>.
      </a>
    </li>
    <li>
      <a
        href="https://www.gallup.com/workplace/247391/fixable-problem-costs-businesses-trillion.aspx"
        rel="noreferrer"
        target="_blank"
      >
        McFeely S. and B. Wigert. (March 13, 2019). “This Fixable Problem Costs U.S. Businesses $1 Trillion.”{' '}
        <em>Gallup</em>.
      </a>
    </li>
  </ul>
);

export default Resources;
