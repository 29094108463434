import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import styles from '@components/ControlPanel.module.css';
import NumberInput from '@components/inputs/NumberInput';
import NumberRangeInput from '@components/inputs/NumberRangeInput';
import ToggleInput from '@components/inputs/ToggleInput';
import Nbsp from '@components/Nbsp';
import ModelParams from '@typing/ModelParams';
import NumberType from '@typing/NumberType';
import Constants from '@utils/Constants';

type Props = {
  modelParams: ModelParams;
};

const ControlPanel = ({ modelParams }: Props) => {
  const intl = useIntl();

  const turnoverRateExplanation = useMemo(
    () => (
      <>
        The yearly turnover rate for your organization. Note that caregiver attrition is higher, averaging 33% according
        to research by the Harvard Business School.
      </>
    ),
    []
  );

  const turnoverCostExplanation = useMemo(
    () => (
      <>
        The cost to your organization in months salary for turnover. Standard estimates for this
        <Nbsp />
        <a
          href="https://www.gallup.com/workplace/247391/fixable-problem-costs-businesses-trillion.aspx"
          rel="noreferrer"
          target="_blank"
        >
          fall between
        </a>
        <Nbsp />
        12 and 24 months.
      </>
    ),
    []
  );

  return (
    <IonGrid>
      <IonRow className={styles.controlsRow}>
        <IonCol className={styles.controlCol} sizeLg="6" sizeMd="6" sizeSm="12" sizeXl="6" sizeXs="12">
          <NumberRangeInput
            explanation={intl.formatMessage({ id: 'controlExplanations.headcount' })}
            labelKey="controlLabels.headcount"
            maxValue={100000}
            minValue={0}
            numberType={NumberType.NUMBER}
            setValue={modelParams.setHeadcount}
            step={100}
            value={modelParams.headcount}
          />
        </IonCol>
        <IonCol className={styles.controlCol} sizeLg="6" sizeMd="6" sizeSm="12" sizeXl="6" sizeXs="12">
          <NumberRangeInput
            explanation={intl.formatMessage({ id: 'controlExplanations.averageComp' })}
            labelKey="controlLabels.averageComp"
            maxValue={300000}
            minValue={0}
            numberType={NumberType.CURRENCY}
            setValue={modelParams.setAverageComp}
            step={1000}
            value={modelParams.averageComp}
          />
        </IonCol>
      </IonRow>
      <IonRow className={styles.controlsRow}>
        <IonCol className={styles.controlCol} sizeLg="6" sizeMd="6" sizeSm="12" sizeXl="6" sizeXs="12">
          <NumberRangeInput
            explanation={turnoverRateExplanation}
            labelKey="controlLabels.turnoverRate"
            maxValue={100}
            minValue={0}
            numberType={NumberType.PERCENTAGE}
            setValue={modelParams.setTurnoverRate}
            step={1}
            value={modelParams.turnoverRate}
          />
        </IonCol>
        <IonCol className={styles.controlCol} sizeLg="6" sizeMd="6" sizeSm="12" sizeXl="6" sizeXs="12">
          <NumberRangeInput
            explanation={turnoverCostExplanation}
            labelKey="controlLabels.turnoverCost"
            maxValue={24}
            minValue={1}
            numberType={NumberType.NUMBER}
            setValue={modelParams.setTurnoverCost}
            step={1}
            value={modelParams.turnoverCost}
          />
        </IonCol>
      </IonRow>
      <IonRow className={styles.controlsRow}>
        <IonCol className={styles.controlCol} sizeLg="6" sizeMd="6" sizeSm="12" sizeXl="6" sizeXs="12">
          <ToggleInput
            explanation={intl.formatMessage({ id: 'controlExplanations.grayceUtilization' })}
            labelKey="controlLabels.grayceUtilization"
            offValue={Constants.UTILIZATION_RATE_LOW}
            onValue={Constants.UTILIZATION_RATE_HIGH}
            setValue={modelParams.setUtilization}
            value={modelParams.utilization}
          />
        </IonCol>
        <IonCol className={styles.controlCol} sizeLg="6" sizeMd="6" sizeSm="12" sizeXl="6" sizeXs="12">
          <NumberInput
            allowClear
            explanation={intl.formatMessage({ id: 'controlExplanations.costOfGrayce' })}
            labelKey="controlLabels.costOfGrayce"
            numberType={NumberType.CURRENCY}
            setValue={modelParams.setCostOfGrayce}
            value={modelParams.costOfGrayce}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ControlPanel;
