type Translations = {
  [key: string]: string;
};

const structuredTranslations = {
  breakdownItems: {
    labels: {
      attritionSavings: 'Value of reduced attrition:',
      averageComp: 'Average employee salary:',
      costOfGrayce: 'Grayce annual program cost:',
      costPerTurnover: 'Replacement cost for turnover:',
      employeesRetained: 'Employees retained with Grayce each year:',
      grayceHeadcount: 'Number of Grayce-enrolled employees per year:',
      grayceTurnoverRate: 'Grayce member attrition rate:',
      headcount: 'Number of eligible employees:',
      productivityFourWeeks: 'Preventing four weeks lost:',
      productivityOneDay: 'Preventing one day lost:',
      productivityOneWeek: 'Preventing one week lost:',
      productivityTotal: 'Total productivity gain:',
      roiDollars: 'Total ROI:',
      roiRatio: 'ROI ratio:',
      strugglingWithCaregiving: 'Employees struggling with caregiving:',
      totalSavings: 'Total savings:',
      turnoverCostMonths: 'Months of productivity lost to turnover:',
      turnoverRate: 'Employee attrition rate:',
      utilization: 'Grayce engagement rate:',
      withCaregivingResponsibilities: 'Employees with caregiving responsibilities:'
    }
  },
  controlExplanations: {
    averageComp: 'The average salary for your workforce.',
    costOfGrayce:
      'The yearly price you have been quoted for the Grayce program. If you have not received a quote, you can leave this blank and still calculate an estimated savings.',
    grayceUtilization:
      'Expected utilization varies based on your commitment to a joint communications strategy with Grayce.',
    headcount: 'The current size of your workforce.'
  },
  controlLabels: {
    averageComp: 'Average compensation',
    costOfGrayce: 'Cost of Grayce',
    grayceUtilization: 'High engagement',
    headcount: 'Headcount',
    turnoverCost: "Turnover cost (in months' salary)",
    turnoverRate: 'Turnover rate'
  },
  pageLinkCopied: 'A link to the report was copied to your clipboard',
  pageTitle: 'Grayce Economic Impact Modeler',
  reportTable: {
    headings: {
      employer: 'Employer',
      grayceRetention: 'Grayce member retention',
      headcount: 'Headcount',
      nonMemberRetention: 'Non-member retention'
    },
    total: 'Total'
  }
};

type GenericNestedMap<T> = Record<string, string | T>;
interface NestedMap extends GenericNestedMap<NestedMap> {}

const flatten = (inputMap: NestedMap, parentKey = ''): Record<string, string> => {
  let result: Record<string, string> = {};

  for (const key in inputMap) {
    const value = inputMap[key];

    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === 'string') {
      result[newKey] = value;
    } else if (typeof value === 'object') {
      const nestedResult = flatten(value, newKey);
      result = { ...result, ...nestedResult };
    }
  }

  return result;
};

const en: Translations = flatten(structuredTranslations);

export default en;
