export const updateValueAsNumberFromInput = (
  value: string | null | undefined | number,
  setter: (val: number | undefined) => void,
  allowClear: boolean
) => {
  if (value) {
    if (typeof value === 'number') {
      setter(value);
    } else {
      setter(parseInt(value));
    }
  } else if (allowClear) {
    setter(undefined);
  }
};
