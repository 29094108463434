import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react';
import { FormattedNumber } from 'react-intl';

import Nbsp from '@components/Nbsp';
import styles from '@components/roiPageSections/RoiBreakdown.module.css';
import ModelParams from '@typing/ModelParams';
import Constants from '@utils/Constants';
import { RoiData } from '@utils/roiCalculator';

type Props = {
  modelParams: ModelParams;
  roiData: RoiData;
};

const RoiBreakdown = ({ modelParams, roiData }: Props) => (
  <IonGrid className={styles.bigBreakdownGrid}>
    <IonRow>
      <IonCol>
        <div className={styles.bigBreakdownSection}>
          <div className={styles.bigBreakdownRow}>
            <div>
              <IonText className={styles.bigBreakdownText}>Savings from reduced turnover</IonText>
            </div>
            <div>
              <IonText className={styles.bigBreakdownText}>
                <FormattedNumber
                  currency="USD"
                  maximumFractionDigits={0}
                  style="currency"
                  value={roiData.attritionSavings}
                />
              </IonText>
            </div>
          </div>

          <IonGrid className={styles.breakdownBlocksGrid}>
            <IonRow>
              <IonCol
                className={styles.breakdownBlockColumn}
                sizeLg="12"
                sizeMd="12"
                sizeSm="12"
                sizeXl="4"
                sizeXs="12"
              >
                <div className={styles.breakdownBlock}>
                  <div className={styles.breakdownBlockTopLine}>
                    <div className={styles.breakdownBlockValue}>
                      <FormattedNumber maximumFractionDigits={0} value={roiData.strugglingWithCaregiving} />
                    </div>
                    <div className={styles.breakdownBlockTitle}>Employees would most benefit from Grayce each year</div>
                  </div>
                  <div className={styles.breakdownBlockExplanation}>
                    According to{' '}
                    <a
                      href="https://www.hbs.edu/managing-the-future-of-work/Documents/The_Caring_Company.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                      research by Harvard Business School
                    </a>
                    , 73% of employees have some form of caregiving responsibilities, and 15% are struggling with
                    significant, timely caregiving stressors. For your headcount of{' '}
                    <FormattedNumber value={modelParams.headcount} />, this results in{' '}
                    <FormattedNumber maximumFractionDigits={0} value={roiData.strugglingWithCaregiving} /> employees who
                    would benefit from Grayce&apos;s services.
                  </div>
                </div>
              </IonCol>

              <IonCol
                className={styles.breakdownBlockColumn}
                sizeLg="12"
                sizeMd="12"
                sizeSm="12"
                sizeXl="4"
                sizeXs="12"
              >
                <div className={styles.breakdownBlock}>
                  <div className={styles.breakdownBlockTopLine}>
                    <div className={styles.breakdownBlockValue}>
                      <FormattedNumber maximumFractionDigits={0} value={roiData.grayceHeadcount} />
                    </div>
                    <div className={styles.breakdownBlockTitle}>Employees will use Grayce each year</div>
                  </div>
                  <div className={styles.breakdownBlockExplanation}>
                    {modelParams.utilization === Constants.UTILIZATION_RATE_LOW ? (
                      <>
                        At a normal level of engagement, we estimate that{' '}
                        <FormattedNumber maximumFractionDigits={0} value={Constants.UTILIZATION_RATE_LOW} />%
                      </>
                    ) : (
                      <>
                        At a high level of engagement, we estimate that{' '}
                        <FormattedNumber maximumFractionDigits={0} value={Constants.UTILIZATION_RATE_HIGH} />%
                      </>
                    )}{' '}
                    of your employees who are struggling with caregiving will sign up for Grayce over the course of our
                    standard <FormattedNumber value={Constants.CONTRACT_YEARS} />
                    -year contract, so each year we will reach{' '}
                    <FormattedNumber maximumFractionDigits={0} value={roiData.grayceHeadcount} /> employees.
                  </div>
                </div>
              </IonCol>

              <IonCol
                className={styles.breakdownBlockColumn}
                sizeLg="12"
                sizeMd="12"
                sizeSm="12"
                sizeXl="4"
                sizeXs="12"
              >
                <div className={styles.breakdownBlock}>
                  <div className={styles.breakdownBlockTopLine}>
                    <div className={styles.breakdownBlockValue}>
                      <FormattedNumber maximumFractionDigits={0} value={roiData.employeesRetained} />
                    </div>
                    <div className={styles.breakdownBlockTitle}>Additional employees will be retained each year</div>
                  </div>
                  <div className={styles.breakdownBlockExplanation}>
                    Our research has shown that Grayce users have a turnover rate that is{' '}
                    <FormattedNumber maximumFractionDigits={0} value={Constants.GRAYCE_TURNOVER_REDUCTION_RATE * 100} />
                    % lower than non-users. At this rate, you will retain{' '}
                    <FormattedNumber maximumFractionDigits={0} value={roiData.employeesRetained} /> additional employees
                    each year. At a turnover cost of{' '}
                    <FormattedNumber
                      currency="USD"
                      maximumFractionDigits={0}
                      style="currency"
                      value={roiData.costPerTurnover}
                    />{' '}
                    in your organization, this results in a savings of{' '}
                    <FormattedNumber
                      currency="USD"
                      maximumFractionDigits={0}
                      style="currency"
                      value={roiData.attritionSavings}
                    />
                    .
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <div className={styles.bigBreakdownSection}>
          <div className={styles.bigBreakdownRow}>
            <div>
              <IonText className={styles.bigBreakdownText}>Productivity savings</IonText>
            </div>
            <div>
              <IonText className={styles.bigBreakdownText}>
                <FormattedNumber
                  currency="USD"
                  maximumFractionDigits={0}
                  style="currency"
                  value={roiData.productivityGains.total}
                />
              </IonText>
            </div>
          </div>

          <IonGrid className={styles.breakdownBlocksGrid}>
            <IonRow>
              <IonCol
                className={styles.breakdownBlockColumn}
                sizeLg="12"
                sizeMd="12"
                sizeSm="12"
                sizeXl="4"
                sizeXs="12"
              >
                <div className={styles.breakdownBlock}>
                  <div className={styles.breakdownBlockTopLine}>
                    <div className={styles.breakdownBlockValue}>
                      <FormattedNumber maximumFractionDigits={0} value={Constants.ONE_DAY_SAVED_RATE * 100} />%
                    </div>
                    <div className={styles.breakdownBlockTitle}>
                      Of Grayce users said they saved at least a day of work
                    </div>
                  </div>
                  <div className={styles.breakdownBlockExplanation}>
                    In a survey of Grayce users, we asked them to report on the amount of work they would have missed
                    without our help.{' '}
                    <FormattedNumber maximumFractionDigits={0} value={Constants.ONE_DAY_SAVED_RATE * 100} />% reported
                    saving at least one day, resulting in a productivity gain of{' '}
                    <FormattedNumber
                      currency="USD"
                      maximumFractionDigits={0}
                      style="currency"
                      value={roiData.productivityGains.oneDay}
                    />
                    <Nbsp />
                    for your headcount.
                  </div>
                </div>
              </IonCol>

              <IonCol
                className={styles.breakdownBlockColumn}
                sizeLg="12"
                sizeMd="12"
                sizeSm="12"
                sizeXl="4"
                sizeXs="12"
              >
                <div className={styles.breakdownBlock}>
                  <div className={styles.breakdownBlockTopLine}>
                    <div className={styles.breakdownBlockValue}>
                      <FormattedNumber maximumFractionDigits={0} value={Constants.ONE_WEEK_SAVED_RATE * 100} />%
                    </div>
                    <div className={styles.breakdownBlockTitle}>
                      Of Grayce users said they saved at least one week of work
                    </div>
                  </div>
                  <div className={styles.breakdownBlockExplanation}>
                    <FormattedNumber maximumFractionDigits={0} value={Constants.ONE_WEEK_SAVED_RATE * 100} />% of Grayce
                    users reported saving at least one week of work, resulting in a productivity gain of{' '}
                    <FormattedNumber
                      currency="USD"
                      maximumFractionDigits={0}
                      style="currency"
                      value={roiData.productivityGains.oneWeek}
                    />
                    <Nbsp />
                    for your headcount.
                  </div>
                </div>
              </IonCol>

              <IonCol
                className={styles.breakdownBlockColumn}
                sizeLg="12"
                sizeMd="12"
                sizeSm="12"
                sizeXl="4"
                sizeXs="12"
              >
                <div className={styles.breakdownBlock}>
                  <div className={styles.breakdownBlockTopLine}>
                    <div className={styles.breakdownBlockValue}>
                      <FormattedNumber maximumFractionDigits={0} value={Constants.FOUR_WEEKS_SAVED_RATE * 100} />%
                    </div>
                    <div className={styles.breakdownBlockTitle}>
                      Of Grayce users said they saved at least four weeks of work
                    </div>
                  </div>
                  <div className={styles.breakdownBlockExplanation}>
                    <FormattedNumber maximumFractionDigits={0} value={Constants.FOUR_WEEKS_SAVED_RATE * 100} />% of
                    Grayce users reported saving at least four weeks of work, resulting in a productivity gain of{' '}
                    <FormattedNumber
                      currency="USD"
                      maximumFractionDigits={0}
                      style="currency"
                      value={roiData.productivityGains.fourWeeks}
                    />
                    <Nbsp />
                    for your headcount.
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <div className={styles.bigBreakdownSection}>
          <div className={styles.bigBreakdownRow}>
            <div></div>
            <div className={styles.bigBreakdownSumLine}></div>
          </div>
          <div className={styles.bigBreakdownRow}>
            <div>
              <IonText className={styles.bigBreakdownText}>Total savings</IonText>
            </div>
            <div>
              <IonText className={styles.bigBreakdownText}>
                <FormattedNumber
                  currency="USD"
                  maximumFractionDigits={0}
                  style="currency"
                  value={roiData.totalSavings}
                />
              </IonText>
            </div>
          </div>
        </div>

        {roiData.roiDollars && modelParams.costOfGrayce && roiData.roiRatio && (
          <div className={styles.bigBreakdownSection}>
            <div className={styles.bigBreakdownRow}>
              <div>
                <IonText className={styles.bigBreakdownText}>Total ROI</IonText>
              </div>
              <div>
                <IonText className={styles.bigBreakdownText}>
                  <FormattedNumber
                    currency="USD"
                    maximumFractionDigits={0}
                    style="currency"
                    value={roiData.roiDollars}
                  />
                  <Nbsp />
                  (<FormattedNumber maximumFractionDigits={2} value={roiData.roiRatio} />
                  x)
                </IonText>
              </div>
            </div>
          </div>
        )}
      </IonCol>
    </IonRow>
  </IonGrid>
);

export default RoiBreakdown;
