import { IonCol, IonGrid, IonInput, IonRow } from '@ionic/react';
import { ReactElement, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from '@components/inputs/Controls.module.css';
import NumberType from '@typing/NumberType';
import { updateValueAsNumberFromInput } from '@utils/formUtils';

type Props = {
  allowClear: boolean;
  explanation: ReactElement | string;
  labelKey: string;
  numberType: NumberType;
  setValue: (value: number | undefined) => void;
  value: number | undefined;
};

const NumberInput = ({ allowClear, explanation, labelKey, numberType, setValue, value }: Props) => {
  const inputRef = useRef<HTMLIonInputElement>(null);

  const inputClass = useMemo(() => {
    if (numberType === NumberType.CURRENCY) {
      return styles.currencyInput;
    } else if (numberType === NumberType.PERCENTAGE) {
      return styles.percentInput;
    }
    return '';
  }, [numberType]);

  useEffect(() => {
    const current = inputRef.current;

    current?.addEventListener('wheel', e => e.preventDefault());
    return () => {
      current?.removeEventListener('wheel', e => e.preventDefault());
    };
  }, [inputRef]);

  return (
    <div className={styles.controlSection}>
      <div className={styles.controlTitle}>
        <FormattedMessage id={labelKey} />
      </div>
      <IonGrid className="ion-no-padding ion-no-margin">
        <IonRow className="ion-no-padding ion-no-margin ion-align-items-center">
          <IonCol className="ion-margin-bottom">
            <IonInput
              className={`${styles.input} ${inputClass}`}
              onIonChange={({ detail }) => updateValueAsNumberFromInput(detail.value, setValue, allowClear)}
              ref={inputRef}
              type="number"
              value={value}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className="ion-no-padding ion-no-margin">
          <IonCol className={styles.numberOnlyControlExplanation}>{explanation}</IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default NumberInput;
