import { Clipboard } from '@capacitor/clipboard';
import { IonButton, IonContent, useIonPopover } from '@ionic/react';
import { MouseEvent, useCallback } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Nbsp from '@components/Nbsp';
import styles from '@components/roiPageSections/Jumbotron.module.css';
import { RoiData } from '@utils/roiCalculator';

type Props = {
  roiData: RoiData;
};

const PageLinkCopiedPopover = () => (
  <IonContent>
    <div className={styles.linkCopiedPopover}>
      <FormattedMessage id="pageLinkCopied" />
    </div>
  </IonContent>
);

const Jumbotron = ({ roiData }: Props) => {
  const [presentpageLinkCopiedPopover, dismissPageLinkCopiedPopover] = useIonPopover(PageLinkCopiedPopover);

  const copyPageLink = useCallback(
    (event: MouseEvent) => {
      Clipboard.write({ string: window.location.href }).then(() => {
        presentpageLinkCopiedPopover({ event: event.nativeEvent });
        setTimeout(() => {
          dismissPageLinkCopiedPopover();
        }, 2000);
      });
    },
    [dismissPageLinkCopiedPopover, presentpageLinkCopiedPopover]
  );

  return (
    <div className={styles.roiHeadline}>
      <div className={styles.roiHeadlinePreamble}>Each year with Grayce you will save</div>
      <div className={styles.roiHeadlineNumber}>
        <FormattedNumber
          currency="USD"
          maximumFractionDigits={0}
          style="currency"
          value={roiData.roiDollars ?? roiData.totalSavings}
        />
      </div>
      {roiData.roiRatio && (
        <div>
          For an ROI of
          <Nbsp />
          <FormattedNumber maximumFractionDigits={2} value={roiData.roiRatio} />x
          <Nbsp />
          the cost of Grayce.
        </div>
      )}
      <IonButton className={styles.shareReportButton} onClick={copyPageLink}>
        Share this report
      </IonButton>
    </div>
  );
};

export default Jumbotron;
