import Constants from '@utils/Constants';

type RoiInputs = {
  averageComp: number;
  costOfGrayce: number | undefined;
  headcount: number;
  turnoverCost: number;
  turnoverRate: number;
  utilization: number;
};

export const calculateRoiData = (inputs: RoiInputs) => {
  const costPerTurnover = inputs.averageComp * (inputs.turnoverCost / 12.0);

  const originalTurnoverRate = inputs.turnoverRate / 100.0;
  const grayceTurnoverRate = originalTurnoverRate * (1.0 - Constants.GRAYCE_TURNOVER_REDUCTION_RATE);

  const originalTurnovers = inputs.headcount * originalTurnoverRate;

  const grayceHeadcount =
    Constants.STRUGGLING_RATE * inputs.headcount * (inputs.utilization / 100.0) * (1.0 / Constants.CONTRACT_YEARS);
  const nonGrayceHeadcount = inputs.headcount - grayceHeadcount;
  const grayceTurnovers = originalTurnoverRate * nonGrayceHeadcount + grayceTurnoverRate * grayceHeadcount;
  const grayceTurnoverReduction = Math.round(originalTurnovers - grayceTurnovers);
  const attritionSavings = grayceTurnoverReduction * costPerTurnover;
  const productivityGainOneDay = grayceHeadcount * Constants.ONE_DAY_SAVED_RATE * (inputs.averageComp / (52.0 * 5.0));
  const productivityGainOneWeek = grayceHeadcount * Constants.ONE_WEEK_SAVED_RATE * (inputs.averageComp / 52.0);
  const productivityGainFourWeeks =
    grayceHeadcount * Constants.FOUR_WEEKS_SAVED_RATE * (inputs.averageComp / 52.0) * 4.0;

  const totalSavings = productivityGainFourWeeks + productivityGainOneDay + productivityGainOneWeek + attritionSavings;

  const roiRatio = inputs.costOfGrayce ? totalSavings / inputs.costOfGrayce : undefined;
  const roiDollars = inputs.costOfGrayce ? totalSavings - inputs.costOfGrayce : undefined;
  return {
    attritionSavings,
    costPerTurnover,
    employeesRetained: grayceTurnoverReduction,
    grayceHeadcount,
    grayceTurnoverRate,
    productivityGains: {
      fourWeeks: productivityGainFourWeeks,
      oneDay: productivityGainOneDay,
      oneWeek: productivityGainOneWeek,
      total: productivityGainFourWeeks + productivityGainOneDay + productivityGainOneWeek
    },
    roiDollars,
    roiRatio,
    strugglingWithCaregiving: inputs.headcount * Constants.STRUGGLING_RATE,
    totalSavings,
    withCaregivingResponsibilities: inputs.headcount * Constants.CAREGIVING_RATE
  };
};

export type RoiData = ReturnType<typeof calculateRoiData>;
