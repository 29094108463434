/* eslint-disable @typescript-eslint/naming-convention */
import { IonButton, IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react';
import { useCallback, useMemo } from 'react';
import { FormattedNumber } from 'react-intl';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

import ControlPanel from '@components/ControlPanel';
import Jumbotron from '@components/roiPageSections/Jumbotron';
import Resources from '@components/roiPageSections/Resources';
import RoiBreakdown from '@components/roiPageSections/RoiBreakdown';
import styles from '@pages/RoiPage.module.css';
import ModelParams from '@typing/ModelParams';
import Constants from '@utils/Constants';
import { calculateRoiData } from '@utils/roiCalculator';

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const RoiPage = () => {
  const costOfGrayceParam = withDefault(NumberParam, Constants.DEFAULT_COST_OF_GRAYCE);
  const [costOfGrayce, setCostOfGrayce] = useQueryParam('gc', costOfGrayceParam);

  const headcountParam = withDefault(NumberParam, Constants.DEFAULT_HEADCOUNT);
  const [headcount, setHeadcount] = useQueryParam('hc', headcountParam);

  const averageCompParam = withDefault(NumberParam, Constants.DEFAULT_AVERAGE_COMP);
  const [averageComp, setAverageComp] = useQueryParam('ac', averageCompParam);

  const turnoverCostParam = withDefault(NumberParam, Constants.DEFAULT_TURNOVER_COST);
  const [turnoverCost, setTurnoverCost] = useQueryParam('tc', turnoverCostParam);

  const turnoverRateParam = withDefault(NumberParam, Constants.DEFAULT_TURNOVER_RATE);
  const [turnoverRate, setTurnoverRate] = useQueryParam('tr', turnoverRateParam);

  const utilizationParam = withDefault(NumberParam, Constants.UTILIZATION_RATE_HIGH);
  const [utilization, setUtilization] = useQueryParam('gu', utilizationParam);

  const modelParams: ModelParams = {
    averageComp,
    costOfGrayce,
    headcount,
    setAverageComp,
    setCostOfGrayce,
    setHeadcount,
    setTurnoverCost,
    setTurnoverRate,
    setUtilization,
    turnoverCost,
    turnoverRate,
    utilization
  };

  const roiData = useMemo(
    () =>
      calculateRoiData({
        averageComp,
        costOfGrayce,
        headcount,
        turnoverCost,
        turnoverRate,
        utilization
      }),
    [averageComp, costOfGrayce, headcount, turnoverCost, turnoverRate, utilization]
  );

  const handleScroll = useCallback(() => {
    const headline = document.querySelector(`.${styles.headline}`);
    const overlay = document.querySelector(`.${styles.roiOverlay}`);
    if (headline && overlay) {
      if (headline.getBoundingClientRect().bottom < 150) {
        overlay.classList.remove(styles.hideOverlay);
        if (!overlay.classList.contains(styles.showOverlay)) {
          overlay.classList.add(styles.showOverlay);
        }
      } else if (overlay.classList.contains(styles.showOverlay)) {
        overlay.classList.remove(styles.showOverlay);
        if (!overlay.classList.contains(styles.hideOverlay)) {
          overlay.classList.add(styles.hideOverlay);
        }
      }
    }
  }, []);

  return (
    <IonPage>
      <div className={styles.roiOverlay}>
        <div className={styles.roiOverlayRow}>
          <div>Each year with Grayce you will save</div>
          <div className={styles.roiOverlayBigNumber}>
            <FormattedNumber
              currency="USD"
              maximumFractionDigits={0}
              style="currency"
              value={roiData.roiDollars ?? roiData.totalSavings}
            />
          </div>
        </div>
      </div>
      <IonContent fullscreen onIonScroll={handleScroll} scrollEvents>
        <IonGrid className="ion-no-padding ion-no-margin">
          <IonRow className="ion-no-padding ion-no-margin">
            <IonCol size="12">
              <IonGrid className="ion-no-padding ion-no-margin">
                <div className={styles.toolbar}>
                  <div className={styles.toolbarLeft}>
                    <div className={styles.logoColumn}>
                      <IonImg className={styles.logo} src="/assets/images/logo-white.svg" />
                    </div>
                    <div className={styles.titleColumn}>Economic Impact Modeler</div>
                  </div>
                  <div className={styles.buttonColumn}>
                    <IonButton
                      className={styles.button}
                      href="https://calendly.com/d/yjn-pxg-m7r"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Talk with an expert
                    </IonButton>
                  </div>
                </div>

                <IonRow className={styles.headline}>
                  <IonCol>
                    <Jumbotron roiData={roiData} />
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol className={styles.dataEntry}>
                    <ControlPanel modelParams={modelParams} />
                  </IonCol>
                </IonRow>

                <IonRow className={styles.report}>
                  <IonCol>
                    <RoiBreakdown modelParams={modelParams} roiData={roiData} />
                  </IonCol>
                </IonRow>

                <IonRow className={styles.reportSectionHeading}>
                  <IonCol>
                    <div id="research">The research</div>
                  </IonCol>
                </IonRow>

                <IonRow className={styles.report}>
                  <div className={styles.researchCopy}>
                    To determine the effect of Grayce on employee turnover, we measured a full one-year period for
                    available clients. Eligibility data included 18,475 employees employed at the beginning of the
                    measurement period. During the period of study, employees who were not Grayce members had an average
                    turnover rate of 21.6%, while Grayce members had a significantly lower turnover rate of 13.3%,
                    observing a 38.4% reduction in turnover.
                  </div>
                </IonRow>

                <IonRow className={styles.reportSectionHeading}>
                  <IonCol>Grayce&apos;s comprehensive value proposition</IonCol>
                </IonRow>

                <IonRow className={styles.report}>
                  <IonCol className={styles.reportColumn}>
                    <IonGrid className={styles.infographicGrid}>
                      <IonRow>
                        <IonCol
                          className={styles.infographicColumn}
                          sizeLg="12"
                          sizeMd="12"
                          sizeSm="12"
                          sizeXl="4"
                          sizeXs="12"
                        >
                          <div className={styles.infographicBlock}>
                            <div className={styles.infographicBlockTopLine}>
                              <div className={styles.infographicBlockImage}>
                                <div className={`${styles.circleBorder} ${styles.circleBorderFiftyFour}`}>
                                  <div className={styles.circle}>
                                    <div className={styles.circleNumber}>54%</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.infographicBlockExplanation}>
                              Of respondents who agree time saved is relevant to their care situation, 54% agree that
                              access to Grayce reduced their need to take family leave, decrease their work hours, or
                              leave their jobs.
                            </div>
                          </div>
                        </IonCol>
                        <IonCol
                          className={styles.infographicColumn}
                          sizeLg="12"
                          sizeMd="12"
                          sizeSm="12"
                          sizeXl="4"
                          sizeXs="12"
                        >
                          <div className={styles.infographicBlock}>
                            <div className={styles.infographicBlockTopLine}>
                              <div className={styles.infographicBlockImage}>
                                <div className={`${styles.circleBorder} ${styles.circleBorderSeventyNine}`}>
                                  <div className={styles.circle}>
                                    <div className={styles.circleNumber}>79%</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.infographicBlockExplanation}>
                              79% of survey respondents agree they would be more likely to take a job because an
                              employer offers Grayce.
                            </div>
                          </div>
                        </IonCol>
                        <IonCol
                          className={styles.infographicColumn}
                          sizeLg="12"
                          sizeMd="12"
                          sizeSm="12"
                          sizeXl="4"
                          sizeXs="12"
                        >
                          <div className={styles.infographicBlock}>
                            <div className={styles.infographicBlockTopLine}>
                              <div className={styles.infographicBlockImage}>
                                <div className={`${styles.circleBorder} ${styles.circleBorderSeventy}`}>
                                  <div className={styles.circle}>
                                    <div className={styles.circleNumber}>70%</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.infographicBlockExplanation}>
                              70% of survey respondents agree they feel their needs are more included at work because
                              their employer offers support from Grayce.
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <div className={styles.quoteBlock}>
                            <Swiper
                              loop
                              modules={[Navigation, Pagination]}
                              navigation
                              onAfterInit={swiper => {
                                setInterval(() => {
                                  swiper.slideNext(500);
                                }, 5000);
                              }}
                              pagination={{ clickable: true }}
                              slidesPerView={1}
                            >
                              <SwiperSlide>
                                <div className={styles.quote}>
                                  &ldquo;Lili was amazing. A single phone call with her made it feel like the weight of
                                  the world came off my shoulders, and her follow up was impeccable. She&apos;s been a
                                  blessing during a chaotic and painful time for our family.&rdquo;
                                </div>
                              </SwiperSlide>
                              <SwiperSlide>
                                <div className={styles.quote}>
                                  &ldquo;I hope my employer will keep Grayce as a benefit forever.&rdquo;
                                </div>
                              </SwiperSlide>
                              <SwiperSlide>
                                <div className={styles.quote}>
                                  &ldquo;My Grace Expert has been fantastic. This is 100% what has helped me the most,
                                  the connection and the expertise.&rdquo;
                                </div>
                              </SwiperSlide>
                              <SwiperSlide>
                                <div className={styles.quote}>
                                  &ldquo;My Expert created a safe space for me to share, took the time to listen and
                                  expertly outlined next steps for me.&rdquo;
                                </div>
                              </SwiperSlide>
                            </Swiper>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                </IonRow>

                <IonRow className={styles.reportSectionHeading}>
                  <IonCol>Resources</IonCol>
                </IonRow>

                <IonRow className={styles.report}>
                  <IonCol className={styles.reportColumn}>
                    <Resources />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default RoiPage;
