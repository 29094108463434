import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import ReactGA from 'react-ga4';
import { IntlProvider } from 'react-intl';
import { Route } from 'react-router';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import enTranslations from '@i18n/en';
import RoiPage from '@pages/RoiPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '@style/variables.css';
import '@style/fonts.css';
import '@style/theme.css';

setupIonicReact();

ReactGA.initialize('G-BMBTV93RXG');

const App = () => (
  <IntlProvider locale="en" messages={enTranslations}>
    <IonApp>
      <IonReactRouter>
        <QueryParamProvider adapter={ReactRouter5Adapter}>
          <IonRouterOutlet id="main">
            <Route exact={true} path="/" render={() => <RoiPage />} />
          </IonRouterOutlet>
        </QueryParamProvider>
      </IonReactRouter>
    </IonApp>
  </IntlProvider>
);

export default App;
